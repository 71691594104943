import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const logementApi = createApi({
  reducerPath: "logementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Logement", "LogementOne", "LogementStatOne"],
  endpoints: (builder) => ({
    logementCreate: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/logement",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    logementWithPerformance: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/logement/with-performance",
        method: "POST",
        body: payload,
      }),
      providesTags: ["Logement"],
    }),

    logementDelete: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/logement/${payload.id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Logement"],
    }),

    logementDeleteAssignation: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/logement-assignment/logement/${payload.id}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Logement"],
    }),

    logementFetch: builder.query({
      query: (id) => `/api/v1/logement/user/${id}`,
      providesTags: ["Logement"],
    }),

    logementFetchOne: builder.query({
      query: (id) => `/api/v1/logement/${id}`,
      providesTags: ["LogementOne"],
    }),

    logementStatsFetchOne: builder.query({
      query: (id) => `/api/v1/logement/stats/${id}`,
      providesTags: ["LogementStatOne"],
    }),

    logementFetchOne2: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/logement/${payload.id}`,
        method: "GET"
      }),
      invalidatesTags: ["LogementOne"],
    }),

    logementSearch: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/logement/search/${payload.id}`,
        method: "GET"
      }),
      invalidatesTags: ["Logement"],
    }),

    logementUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/logement/${payload.id}`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ["Logement"],
    }),

    productsFetch: builder.query({
      query: () => `/api/v1/produit/type/kit`,
      providesTags: ["Logement"],
    }),

    // INSTRUCTION
    instructionCreate: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/logement/instruction/",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    instructionUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/logement/instruction/${payload.id}`,
        method: "PATCH",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    // CART
    cartCreate: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/cart",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    cartClear: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/cart/clear",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    cartFetchLinge: builder.query({
      query: (logementId) => `/api/v1/cart/logement/${logementId}/`,
      providesTags: ["Logement"],
    }),

    cartFetchAmenities: builder.query({
      query: (logementId) => `/api/v1/cart/logement/${logementId}/amenitie`,
      providesTags: ["Logement"],
    }),

    cartFetchOne: builder.query({
      query: (cartId) => `/api/v1/cart/${cartId}`,
      providesTags: ["Logement"],
    }),

    cartUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/cart/${payload.id}`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ["Logement"],
    }),

    cartDecrease: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/cart/decrease",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    // AUTOMATISATION
    icalendarCreate: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/icalendar/",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

   /* icalendarQueueCreate: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/icalendar/queue/",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),*/

    icalendarFetch: builder.query({
      query: (logementId) => `/api/v1/logement/${logementId}/icalendar`,
      providesTags: ["Logement"],
    }),

    icalendarStopsynchro: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/icalendar/stopsynchro",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    icalendarRestartsynchro: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/icalendar/restartsynchro",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    icalendarDelete: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/icalendar/delete",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    // COÛT DURÉE
    coutDureeUpdate: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/cout-duree/mobile",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    //EQUIPE DE MENAGE
    equipeMenageCreate: builder.mutation({
      query: (payload) => ({
        url: "api/v1/logement-assignment/sourcing",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    getStatUser: builder.mutation({
      query: (payload) => ({
        url: "/api/v1/performance/user/entretien-equipe",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    equipeMenageCheck: builder.query({
      query: (logementId) => `/api/v1/logement-assignment/logement/${logementId}`,
      providesTags: ["Logement"],
    }),

    // ASSIGNATION
    logementAssignment: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/logement-assignment/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Logement"],
    }),

    majoratePricings: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/logement/${payload.logementId}/pricing-majoration`,
        method: "POST",
        body: payload.data,
      }),
      invalidatesTags: ["Logement"],
    }),    
    chargeLaundryCautionFees: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/logement/${payload.id}/laundry-caution-fees`,
        method: "POST",
        body: {mode: payload.mode},
      }),
      invalidatesTags: ["Logement"],
    }),   
    
    getPartnerRevenues: builder.query({
      query: (data) => `/api/v1/logement/${data.logementId}/prestations/pricings?userId=${data.userId}`,
      providesTags: ["Logement"],
    }),
  }),
});

export const {
  useLogementCreateMutation,
  useLogementWithPerformanceMutation,
  useLogementFetchQuery,
  useLogementFetchOneQuery,
  useLogementStatsFetchOneQuery,
  useLogementFetchOne2Mutation,
  useLogementUpdateMutation,
  useLogementDeleteMutation,
  useLogementDeleteAssignationMutation,
  useProductsFetchQuery,
  useCartCreateMutation,
  useCartClearMutation,
  useCartFetchLingeQuery,
  useCartFetchAmenitiesQuery,
  useCartFetchOneQuery,
  useCartDecreaseMutation,
  useCartUpdateMutation,
  useInstructionCreateMutation,
  useInstructionUpdateMutation,
  useIcalendarCreateMutation, useIcalendarQueueCreateMutation, useIcalendarDeleteMutation, useIcalendarFetchQuery, useIcalendarRestartsynchroMutation, useIcalendarStopsynchroMutation,
  useCoutDureeUpdateMutation,
  useEquipeMenageCreateMutation,
  useEquipeMenageCheckQuery,
  useGetStatUserMutation,
  useLogementAssignmentMutation,
  useMajoratePricingsMutation,
  useChargeLaundryCautionFeesMutation,
  useGetPartnerRevenuesQuery
} = logementApi;
